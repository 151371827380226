//
// Extended from Bootstrap
//

@import "./solana/navbar";

// Former lift.scss
$btnBorder: 1px;
$translateY: 3px;
$box-shadow-lift:
  0 1rem 2.5rem fade-out($black, 0.9),
  0 0.5rem 1rem -0.75rem fade-out($black, 0.9) !default;

.lift {
  transition:
    box-shadow 0.25s ease,
    transform 0.25s ease;
}

.lift:not([disabled]):hover,
.lift:not([disabled]):focus {
  box-shadow: $box-shadow-lift !important;
  transform: translate3d(0, -$translateY, 0);
}

.lift:not([disabled]):hover::after {
  content: "";
  position: absolute;
  left: -$btnBorder;
  top: -$btnBorder;
  height: calc(100% + $translateY + 2 * $btnBorder);
  width: calc(100% + 2 * $btnBorder);
}

// Former sizing.scss
@each $width, $widthValue in $container-max-widths {
  @include media-breakpoint-up($width) {
    @each $size, $sizeValue in $sizes {
      // Percentage

      .h-#{$width}-#{$size} {
        height: $sizeValue !important;
      }

      .w-#{$width}-#{$size} {
        width: $sizeValue !important;
      }

      .mw-#{$width}-#{$size} {
        max-width: $sizeValue !important;
      }

      // Viewport units

      .vh-#{$width}-#{$size} {
        height: #{$size}vh;
      }

      .vw-#{$width}-#{$size} {
        width: #{$size}vw;
      }
    }
  }
}

// Former dropdown.scss
.dropdown-toggle:after {
  content: "";
  border: 0;
}

// Former wrapper `styled` component

body {
  font-family: Diatype, var(--font-family-sans-serif);
  color: #fff;
  font-size: 1.3125rem;
  line-height: 140%;
}

::selection {
  background: $primary;
  color: $black;
}

.bg-black {
  background-color: #000 !important;
}

.text-black {
  color: #000000 !important;
}

/* Desktop/Display XLarge */
h1,
.h1 {
  font-size: clamp(3.5rem, 8vw, 6rem);
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.03rem;
}

/* Desktop/Display Large */
h2,
.h2 {
  font-size: clamp(2.625rem, 8vw, 4.5rem);
  font-weight: bold;
  line-height: 105%;
  letter-spacing: -0.0425rem;
}

/* Desktop/Display Medium */
h3,
.h3 {
  font-size: clamp(2.25rem, 8vw, 3.5rem);
  font-weight: bold;
  line-height: 105%;
}

/* Desktop/Headline Large */
h4,
.h4 {
  font-size: 2.625rem;
  line-height: 110%;
  letter-spacing: -1px;
  font-weight: 700;
}

/* Desktop/Headline Medium */
.h5,
h5 {
  font-size: 1.75rem;
  line-height: 118%;
  font-weight: bold;
}

/* Desktop/Headline Small */
h6,
.h6 {
  font-size: 1.5rem;
  line-height: 117%;
}

.smaller {
  font-size: 1rem;
}

.small {
  font-size: 1.1rem;
}

.clamp {
  font-size: clamp(1.125rem, 8vw, 1.3125rem) !important;
}

.large {
  font-size: 1.75rem !important;
  line-height: 120% !important;
}

.subdued {
  color: #c4c4c4;
}

// Custom border color for the form controls
.form-control {
  border: 1px solid;
  border-color: #409088;
}

// Adapt container for screens wor
@media (min-width: 1441px) {
  .container-xxl,
  .container-xl,
  .container-md,
  .container {
    max-width: 1200px !important;
  }
}

// Bootstrap until v.5 doesn't have this helper
.overflow-visible {
  overflow: visible !important;
}

// Overwrite modal content theme
.dark-modal-content {
  background: #18191e;
  border-radius: 1.3125rem;
  border: 0;
}

// Vimeo wrapper custom iframe styles
.vimeo-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  background-color: #000;
}

.vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.still-vimeo-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Update the reboot's <code> style to scale based on parent's size
// Use 0.9 because the Mono glyphs are usually larger
code {
  font-size: 0.9em !important;
}

// Cursor pointer helper
.cursor-pointer {
  cursor: pointer;
}

.youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.link-unstyled {
  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    font-style: inherit;
    color: inherit;
    background-color: transparent;
    font-size: inherit;
    text-decoration: none;
    font-variant: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
    border-radius: inherit;
    outline: inherit;
    box-shadow: inherit;
    vertical-align: inherit;
  }
}

.kg-embed-card {
  position: relative !important;
  padding-bottom: 56.25% !important;
  height: auto !important;
}

.kg-embed-card iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Safari 15+ displays a gray border while loading
// https://nextjs.org/docs/api-reference/next/image
@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading="lazy"] {
    clip-path: inset(0.6px);
  }
}

:root {
  --body-bg: #121212;
  --body-text: #fafafa;
  --body-text-secondary: #848895;

  --scrollbar-track: #414141;
  --scrollbar-thumb: #666;
  --scrollbar-thumb-hover: #888;

  --mdx-text-color: #c0c0c0;
  --mdx-text-color-2: #3f3a4e;
  --mdx-text-color-3: #818384;
  --mdx-link-color: #00ffbd;
  --mdx-link-color-hover: #00ffbd;

  --mdx-bg: #222121;
  --mdx-table-bg-1: #151515;
  --mdx-table-bg-2: #181818;

  --mdx-details-bg: #1d1f21;
  --mdx-details-border: #414141;

  --callout-text-color: #e2e2e2;
  --callout-yellow-bg: #a36a00;
  --callout-yellow-border: #e6ab09;
  --callout-green-bg: #086835;
  --callout-green-border: #118e4c;
  --callout-red-bg: #5c0909;
  --callout-red-border: #810e0e;
  --callout-blue-bg: #032a4a;
  --callout-blue-border: #0e4d81;

  &.light {
    --body-bg: #fff;
    --body-text: #121212;

    --scrollbar-track: #f2f4f7;
    --scrollbar-thumb: #d0d0dc;
    --scrollbar-thumb-hover: #9d9dae;

    --mdx-text-color: #3f3a4e;
    --mdx-text-color-2: #a5a5a5;
    --mdx-text-color-3: #4f5152;
    --mdx-link-color: #9945ff;
    --mdx-link-color-hover: #9945ff;

    --mdx-bg: #d5d5d5;
    --mdx-table-bg-1: #e7e7e7;
    --mdx-table-bg-2: #dbdbdb;

    --mdx-details-bg: #dedede;
    --mdx-details-border: #c2c2c2;

    --callout-text-color: #333333;
    --callout-yellow-bg: #edbd5e;
    --callout-yellow-border: #e6ab09;
    --callout-green-bg: #58d190;
    --callout-green-border: #29b369;
    --callout-red-bg: #f64f4f;
    --callout-red-border: #c22525;
    --callout-blue-bg: #5fb5fc;
    --callout-blue-border: #4c94cf;
  }
}

body {
  // the &.dark {} theme is implicit
  transition: background-color 0.3s ease;
  background-color: var(--body-bg);
}
