// Diatype
@import "../fonts/fonts.css";

// Solana variables
@import "solana-variables";

// Bootstrap core
@import "~bootstrap/scss/bootstrap";

// Solana core & page-specific styles
@import "solana";
@import "solana/blog";
@import "solana/community";
@import "solana/validators";
@import "solana/modals";

// Solana-lib
@import "~@solana-foundation/solana-lib/dist/styles.css";

// Algolia docsearch
@import "~@docsearch/css";
@import "./docsearch.scss";
