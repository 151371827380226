// Post cards

.news-hero {
  background-color: #000;
  width: 100%;
  .main-container {
    position: relative;
    height: 250px;
    display: flex;
    align-items: center;
    @media (min-width: 576px) {
      height: 300px;
    }
    @media (min-width: 992px) {
      height: 380px;
    }
    .background-gradient {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      &.primary {
        height: 200px;
        background: linear-gradient(
          180deg,
          #000000 0%,
          rgba(0, 0, 0, 0) 39.21%
        );
      }
      &.secondary {
        opacity: 0.6;
        height: 100%;
        background: linear-gradient(
          87.53deg,
          #000000 17.14%,
          rgba(41, 41, 41, 0) 119.68%
        );
        display: none;
        @media (min-width: 992px) {
          display: block;
        }
      }
    }
    .hero-title {
      z-index: 10;
      width: 100%;
      max-width: calc(1040px + 3rem);
      margin: auto;
      padding: 0 1.5rem;
      @media (min-width: 576px) {
        max-width: calc(1040px + 6rem);
        padding: 0 3rem;
      }
      @media (min-width: 992px) {
        max-width: calc(1040px + 9rem);
        padding: 0 4.5rem;
      }
    }
  }
}

.news-page {
  position: relative;
  z-index: 1;
  .main-container {
    width: 100%;
    max-width: calc(1040px + 3rem);
    margin: auto;
    padding: 0 1.5rem;
    @media (min-width: 576px) {
      max-width: calc(1040px + 6rem);
      padding: 0 3rem;
    }
    @media (min-width: 992px) {
      max-width: calc(1040px + 9rem);
      padding: 0 4.5rem;
    }
    & > section {
      width: 100%;
      display: flex;
      flex-direction: column;
      column-gap: 1rem;
      row-gap: 2rem;
      @media (min-width: 750px) {
        flex-direction: row;
      }
      @media (min-width: 992px) {
        column-gap: 2rem;
      }
      .left-post-feed {
        display: flex;
        flex-direction: column;
        row-gap: 4rem;
        flex: 1;
      }
      .right-post-feed {
        display: none;
        flex-direction: column;
        row-gap: 2rem;
        min-width: 240px;
        max-width: 100%;
        @media (min-width: 750px) {
          display: flex;
          max-width: 240px;
        }
        & > div {
          padding-bottom: 1rem;
          border-bottom: 1px solid #ffffff50;
          &:first-child {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
        h2 {
          font-size: 1.75rem !important;
          @media (min-width: 750px) {
            font-size: 1.125rem !important;
            margin-bottom: 1rem !important;
          }
        }
      }
    }
  }
}

.post-feed {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr;
  gap: 75px 25px;

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  .featured {
    grid-area: 1 / 1 / 1 / 1;

    @media (min-width: 900px) {
      grid-area: 1 / 1 / 1 / 3;
    }
  }

  > a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}
