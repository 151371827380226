$enable-negative-margins: true;
$link-decoration: none;
$enable-shadows: false;
$min-contrast-ratio: 2.5;
$enable-validation-icons: false !default;

// Colors

html,
body {
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --font-family-sans-serif: Helvetica, Arial, system-ui, -apple-system,
    Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif,
    BlinkMacSystemFont, "Segoe UI", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  --font-mono: "Roboto Mono", SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  scroll-behavior: smooth;
}

$white: #ffffff;
$gray-100: #f9fdfc;
$gray-200: #f1f8f6;
$gray-300: #d9efe7;
$gray-400: #c6e6de;
$gray-500: #abd5c6;
$gray-600: #86b8b6;
$gray-700: #409088;
$gray-800: #387462;
// $gray-900: #1b4e3f;
$black: #232323;

$primary: #00ffbd;
$primary-desat: #42ba96;
$secondary: $gray-700;
$success: #42ba96;
$info: #b45be1;
$info-muted: #9272a3;
$warning: #d83aeb;
$danger: #43b5c5;
$light: $gray-100;
// $dark: $gray-900;

$card-background: #26262b;
$card-hover: #454549;
$card-background-light: #f9f9fb;
$card-background-dark: #000005;
$new-gray: #c8c8d6;
$black-bg: #232323;

$text-info-muted: $info-muted;
$body-bg: #000 !default;
$body-color: $black !default;

// Old _variables.scss file's content

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    // 4px
    2: (
        $spacer * 0.5,
      ),
    // 8px
    3: (
        $spacer * 0.75,
      ),
    // 12px
    4: $spacer,
    // 16px
    5: (
        $spacer * 1.5,
      ),
    // 24px
    6: (
        $spacer * 2,
      ),
    // 32px
    7: (
        $spacer * 2.5,
      ),
    // 40px
    8: (
        $spacer * 3,
      ),
    // 48px
    9: (
        $spacer * 4,
      ),
    // 64px
    10: (
        $spacer * 5,
      ),
    // 80px
    11: (
        $spacer * 6,
      ),
    // 96px
    12: (
        $spacer * 8,
      ),
    // 128px
    13: (
        $spacer * 10,
      ),
    // 160px
    14: (
        $spacer * 12,
      ),
    // 192px
    15: (
        $spacer * 16,
      ),
    // 256px
    16: (
        $spacer * 25,
      ),
    // 400px
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    110: 110%,
    120: 120%,
    130: 130%,
    140: 140%,
    150: 150%,
    auto: auto,
  ),
  $sizes
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1141px,
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 40px !default;
