.card {
  position: relative;
  width: 100%;

  &.videos-card {
    min-width: 279px;
    height: 550px;
  }
}

.community-page {
  $color-hackathon-dark: #e3e3e3;

  p.label {
    font-size: 1rem;
    line-height: 1.2;
  }

  p.small {
    font-size: 0.875rem;
    line-height: 1.05;
  }

  .hero {
    width: 100%;
    position: relative;
    padding-top: 5.5rem;

    .main-container {
      position: relative;

      .background-gradient {
        position: absolute;
        z-index: -1;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          180deg,
          #000000 0%,
          rgba(0, 0, 0, 0) 39.21%
        );
      }
    }

    &-container {
      padding-top: 8rem;
      padding-bottom: 3.5rem;
    }
  }

  .hero-title {
    p {
      font-size: clamp(1.3125rem, 8vw, 1.5rem) !important;
      line-height: 2rem;
    }
  }

  .community-socials {
    margin-top: 4rem;

    .h2-sub {
      font-size: 1.23rem;
    }

    &-content {
      margin-top: 2rem;
      row-gap: 3rem;
    }
  }

  .community-socials-item {
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 1rem;
    color: $card-background-light;
    background-color: $black-bg;
    stroke: $black-bg;
    transition: background-color 0.35s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:hover {
      text-decoration: none;
      background-color: $card-background-light;
      color: $black-bg;
      stroke: $white;

      .community-socials-icon svg {
        fill: $black-bg;
      }
    }
  }

  .community-socials-icon {
    width: 2rem;
    height: 2rem;
    margin: 1.5rem 0 0.5rem;

    svg {
      fill: $card-background-light;
      stroke-width: 0;
      transition: fill 0.3s;
    }
  }

  .community-socials-amount {
    margin-bottom: 0;
    word-break: break-word;
  }

  .featured-videos {
    margin-top: 9rem;
    padding-bottom: 4rem;

    .h2 {
      margin-bottom: 0;
    }

    .links {
      display: flex;
      column-gap: 0.75rem;
      align-items: center;
      justify-content: flex-start;

      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }
    }

    .videos {
      .card-container {
        border-bottom-width: 1px;
        @include media-breakpoint-down(md) {
          border-bottom-width: 0 !important;
        }
      }

      .featured {
        .video-card-title {
          /* Desktop/Headline Large */
          font-size: 2.625rem;
          line-height: 110%;
          letter-spacing: -1px;
          font-weight: 700;
        }
        .card-container {
          border-bottom-width: 0px;
        }
      }
      .related {
        h5 {
          text-transform: uppercase;
          color: #9945ff;
        }
      }
    }

    #videos::-webkit-scrollbar {
      display: none;
    }

    #videos {
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .overlay-left,
    .overlay-right {
      position: absolute;
      top: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity 0.3s;
    }

    .overlay-left {
      left: 0px;
      min-width: 6rem;
      background: linear-gradient(to left, transparent, $color-hackathon-dark);
    }

    .overlay-right {
      right: 0px;
      min-width: 6rem;
      background: linear-gradient(to right, transparent, $color-hackathon-dark);
    }

    [data-overflowing="both"],
    [data-overflowing="left"] {
      .overlay-left {
        opacity: 1;
      }
    }

    [data-overflowing="both"],
    [data-overflowing="right"] {
      .overlay-right {
        opacity: 1;
      }
    }

    &-items {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(1, 1fr);
      }
      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(2, 1fr);
      }

      .videos-card {
        align-items: center;
        border-radius: 1rem;
        @include media-breakpoint-up(lg) {
          align-items: flex-start;
        }

        .body-button {
          display: flex;
          justify-content: center;

          @include media-breakpoint-up(lg) {
            justify-content: flex-start;
          }
        }
      }
    }
  }

  .community-popuplar {
    background-color: $new-gray;
    padding: 0 4rem 3rem;

    .cards {
      margin-top: 4.5rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .podcasts {
    margin-top: 5rem;
    margin-bottom: 5rem;

    .card {
      border-radius: 1rem;
    }

    .first-line-image {
      height: 100%;
    }

    .community-card-body {
      .second-line-image {
        min-height: 11rem;
      }
    }
  }

  .sticky-title {
    position: sticky;
    position: -webkit-sticky;
    top: 5rem;
  }
}
